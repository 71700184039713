


















































































import { reqArticleList } from "@/api";
import { NewsColumns } from "@/types/enum";
import { readNewsDetailHandler, transformNewsString } from "@/utils";
import { Vue, Component } from "vue-property-decorator";

@Component({
  name: "BaAndYu"
})
export default class extends Vue {
  news: any[] = [];
  loading = false;
  page = 1;
  size = 10;

  get paginationNews() {
    const { page, size } = this;
    return this.news.slice((page - 1) * size, page * size);
  }

  readDetailHandler(item: any) {
    readNewsDetailHandler(item);
  }

  async initList() {
    const { data: list } = await reqArticleList(NewsColumns.BAANDYU);
    if (list && list.length > 0) {
      this.news = transformNewsString(list);
    }
  }

  open(href: string) {
    const loction = window.location.origin;
    window.open(loction + href, "_blank");
  }

  read = readNewsDetailHandler;

  created() {
    this.initList();
  }
}
